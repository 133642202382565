import React, { useEffect, useState } from 'react';
import loginStyle from './review-screens.module.scss';
import Demo_image from '../assets/loginsettings/demo_image.svg';
import Eye_icon from '../assets/loginsettings/eye_icon.svg';
import EyeClose_icon from '../assets/loginsettings/eyeClose_icon.svg';
import Key_icon from '../assets/loginsettings/key_icon.svg';
import BackArrow_icon from '../assets/loginsettings/backArrow_icon.svg';
import TickWhite_icon from '../assets/loginsettings/tickWhite_icon.svg';
import TickGreen_icon from '../assets/loginsettings/tickGreen_icon.svg';
import { Input } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { antdMessageError, antdMessageSuccess } from '../common/antdMessageComponent';
import axios from 'axios';
import { checkSuccessCode } from '../../utills/validator';
import { handleAuthCodeGeneration } from '../../utills/common';
import { CSCSendEvent, eventLocation, eventType } from '../../analytics';
export function ResetPassword() {
  const queryParams = new URLSearchParams(window.location.search);
  const commanUrlValue = useSelector((state) => state.commanUrlValue);
  const navigate = useNavigate();
  const data = useSelector((state) => state.newClientConfig.resetPassword);
  const toggleData = useSelector((state) => state.loginSetting);
  const clientGroupId = useSelector((state) => state.clientGroupId);
  const loginDetails = useSelector((state) => state.loginDetails);
  const authCode = useSelector((state) => state.authCode);

  const [password, setPassword] = useState({
    setPasswordVisible: false,
    confirmPasswordVisible: false,
    setPassword: '',
    confirmPassword: '',
    btnDisabled: true,
    error: false,
  });
  const [passwordConfig, setPasswordConfig] = useState({
    minLength: false,
    MixCase: false,
    alphaNumeric: false,
    specialChar: false,
  });

  useEffect(() => {
    setPasswordConfig({
      minLength: false,
      MixCase: !toggleData?.passwordConfig?.MixCase,
      alphaNumeric: !toggleData?.passwordConfig?.alphaNumeric,
      specialChar: !toggleData?.passwordConfig?.specialChar,
    });
  }, [toggleData?.passwordConfig]);

  function ValidatePassword(password) {
    const defaultValues = {
      minLength: true,
      alphaNumeric: true,
      MixCase: true,
      specialChar: true,
    };
    if (password.length < toggleData?.passwordConfig?.minLength) {
      defaultValues.minLength = false;
    }
    if (toggleData?.passwordConfig?.alphaNumeric) {
      if (!password.match(/[a-zA-Z]/) || !password.match(/[0-9]/)) defaultValues.alphaNumeric = false;
    }
    if (toggleData?.passwordConfig?.MixCase) {
      if (!password.match(/[a-z]/) || !password.match(/[A-Z]/)) defaultValues.MixCase = false;
    }
    if (toggleData?.passwordConfig?.specialChar) {
      if (!password.match(/[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]+/)) defaultValues.specialChar = false;
    }
    setPasswordConfig(defaultValues);
    return true;
  }
  const checkinput = (value) => {
    if (ValidatePassword(value)) {
      return setPassword({ ...password, setPassword: value });
    }
  };
  const handleChangePassword = async () => {
    try {
      const changePasswordResponse = await axios({
        method: 'post',
        url: `${process.env.REACT_APP_API_URL}/sso/change-password`,
        data: {
          clientGroupId: clientGroupId,
          ...(loginDetails?.phone && { phone: loginDetails?.phone }),
          ...(loginDetails?.email && { email: loginDetails?.email }),
          clientId: commanUrlValue.clientId,
          pass: password?.setPassword,
          cnfPass: password?.confirmPassword,
        },
        headers: { Authorization: `Bearer ${authCode}` },
      });
      if (checkSuccessCode(changePasswordResponse)) {
        antdMessageSuccess('Password reset successfully');
        handleAuthCodeGeneration(
          authCode,
          commanUrlValue.redirectUrl,
          commanUrlValue,
          commanUrlValue.clientId,
          navigate,
        );
      }
    } catch (error) {
      antdMessageError(error?.response?.data?.message);
    }
  };

  useEffect(() => {
    const ssoResetPasswordViewObject = {
      eventType: eventType.VIEW,
      eventLocation: eventLocation.SSO_RESET_PASSWORD,
      clientId: commanUrlValue?.clientId,
      anonId: commanUrlValue?.anonId,
      paywallId: commanUrlValue?.paywallId,
      url: decodeURIComponent(window.location.href || ''),
      segmentId: commanUrlValue?.segmentId,
      journeyId: commanUrlValue?.journeyId,
    };
    CSCSendEvent(ssoResetPasswordViewObject);
  }, []);

  useEffect(() => {
    const handleBeforeUnload = () => {
      localStorage.setItem('redirected', 'true');
    };
    window.addEventListener('beforeunload', handleBeforeUnload);
    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, []);

  useEffect(() => {
    const redirected = localStorage.getItem('redirected');
    if (redirected) {
      localStorage.removeItem('redirected');
      navigate(`/login?${queryParams.toString()}`);
    }
  }, [navigate]);
  const keyDownHandler = (event) => {
    if (event.key === 'Enter') {
      if (!password?.btnDisabled) {
        handleChangePassword();
      }
    }
  };
  return (
    <div className={loginStyle['main-container']}>
      <div className={loginStyle['header-logo']}>
        <img className={loginStyle['image-logo']} src={toggleData?.brandLogoUrl || Demo_image} alt="Demo_image" />
      </div>
      <div className={loginStyle['p-4']}></div>
      <div className={loginStyle['container']}>
        <div className={loginStyle['child-container']}>
          <div className={loginStyle['backArrow_icon']}>
            <img
              src={BackArrow_icon}
              alt="BackArrow"
              onClick={() => navigate(`/newvarifyotp?${queryParams.toString()}`)}
            />
          </div>
          <div className={loginStyle['heading-container']}>
            <div>
              <div className={loginStyle['heading-text']} style={data?.formTitle}>
                {data?.formTitle?.text || 'Reset password'}
              </div>
            </div>
          </div>
          <div className={loginStyle['setpasswprd-form-container']}>
            <div className={loginStyle['input-container-common']}>
              <img src={Key_icon} alt="Key_icon" />
              <Input
                placeholder={data?.setPassword?.text || 'Set Password'}
                style={data?.setPassword}
                type={password.setPasswordVisible ? 'text' : 'password'}
                onChange={(e) => checkinput(e.target.value)}
              />
              <img
                src={password.setPasswordVisible ? Eye_icon : EyeClose_icon}
                alt="EyeClose_icon"
                onClick={() => {
                  setPassword({
                    ...password,
                    setPasswordVisible: !password.setPasswordVisible,
                  });
                }}
                style={{ cursor: 'pointer' }}
              />
            </div>
            <div className={loginStyle['input-container-common']}>
              <img src={Key_icon} alt="Key_icon" />
              <Input
                placeholder={data?.confirmPassword?.text || 'Confirm Password'}
                style={data?.confirmPassword}
                type={password.confirmPasswordVisible ? 'text' : 'password'}
                onChange={(e) => {
                  if (e.target.value === password?.setPassword) {
                    setPassword({
                      ...password,
                      confirmPassword: e.target.value,
                      btnDisabled: false,
                      error: false,
                    });
                  } else {
                    setPassword({ ...password, btnDisabled: true, error: true });
                  }
                }}
                onKeyDown={keyDownHandler}
              />
              <img
                src={password.confirmPasswordVisible ? Eye_icon : EyeClose_icon}
                alt="Eye_icon"
                onClick={() => {
                  setPassword({
                    ...password,
                    confirmPasswordVisible: !password.confirmPasswordVisible,
                  });
                }}
                style={{ cursor: 'pointer' }}
              />
            </div>
            {password.error && <div className={loginStyle['error-message']}>Ensure both passwords are the same.</div>}
            <div className={loginStyle['validation-container']}>
              {(toggleData?.passwordConfig?.minLength > 0 ||
                toggleData?.passwordConfig?.alphaNumeric ||
                toggleData?.passwordConfig?.MixCase ||
                toggleData?.passwordConfig?.specialChar) && (
                <>
                  <div>Password should contain the following parameters:</div>
                  {toggleData?.passwordConfig?.minLength > 0 && (
                    <div className={loginStyle['validation_text']}>
                      <img src={passwordConfig.minLength ? TickGreen_icon : TickWhite_icon} alt="TickGreen_icon" />
                      <div>Include at least {toggleData?.passwordConfig?.minLength} characters</div>
                    </div>
                  )}
                  {toggleData?.passwordConfig?.alphaNumeric && (
                    <div className={loginStyle['validation_text']}>
                      <img src={passwordConfig.alphaNumeric ? TickGreen_icon : TickWhite_icon} alt="TickGreen_icon" />
                      <div>Include Alpha numerical</div>
                    </div>
                  )}
                  {toggleData?.passwordConfig?.MixCase && (
                    <div className={loginStyle['validation_text']}>
                      <img src={passwordConfig.MixCase ? TickGreen_icon : TickWhite_icon} alt="TickGreen_icon" />
                      <div>Include Mixed case</div>
                    </div>
                  )}
                  {toggleData?.passwordConfig?.specialChar && (
                    <div className={loginStyle['validation_text']}>
                      <img src={passwordConfig.specialChar ? TickGreen_icon : TickWhite_icon} alt="TickWhite_icon" />
                      <div>Include Special characters (for example, !, $, #, %).</div>
                    </div>
                  )}
                </>
              )}
            </div>
          </div>
          <button
            className={loginStyle['btn-style']}
            style={{
              ...data?.buttonText,
              opacity: password?.btnDisabled ? '0.5' : '1',
              cursor: password?.btnDisabled ? 'not-allowed' : 'pointer',
            }}
            disabled={password?.btnDisabled}
            onClick={() => handleChangePassword()}
          >
            {data?.buttonText?.text || 'Reset Password'}
          </button>
        </div>
      </div>
    </div>
  );
}
