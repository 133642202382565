import { createSlice } from '@reduxjs/toolkit';

export const counterSlice = createSlice({
  name: 'counter',
  initialState: {
    clientGroupId: '',
    loginSetting: {},
    newClientConfig: {},
    clientConfig: {},
    userInfo: {},
    parsedValue: {},
    commanUrlValue: {},
    clientDetails: {
      phone: '',
      email: '',
      emailPhone: '',
      fullName: '',
      gender: '',
      dateOfBirth: '',
      loginType: '',
      primary: '',
      dail_code: '',
      secondaryEmail: '',
      secondaryPhone: '',
    },
    loginDetails: {
      email: '',
      phone: '',
      emailPhone: '',
      loginType: '',
    },
    authCode: '',
  },
  reducers: {
    newLoginSetting: (state, action) => {
      state.clientGroupId = action.payload?.groupId;
      state.loginSetting = action.payload?.loginCustomization;
      state.newClientConfig = JSON.parse(action.payload?.loginCustomization?.uiConfig);
    },
    addClientConfig: (state, action) => {
      state.clientConfig = action.payload;
      state.parsedValue = JSON.parse(action.payload?.uiConfig);
    },
    addUserInfo: (state, action) => {
      state.userInfo = action.payload;
    },
    commanUrlValue: (state, action) => {
      state.commanUrlValue = action.payload;
    },
    setRegisterDetails: (state, action) => {
      state.clientDetails = action.payload;
    },
    setLoginDetails: (state, action) => {
      state.loginDetails = action.payload;
    },
    setAuthToken: (state, action) => {
      console.log(action.payload, 'action.payload');
      state.authCode = action.payload;
    },
  },
});
// Action creators are generated for each case reducer function
export const {
  newLoginSetting,
  addClientConfig,
  addUserInfo,
  commanUrlValue,
  setRegisterDetails,
  setLoginDetails,
  setAuthToken,
} = counterSlice.actions;

export default counterSlice.reducer;
