import React, { useEffect, useState } from 'react';
import loginStyle from './review-screens.module.scss';
import Demo_image from '../assets/loginsettings/demo_image.svg';
import BackArrow_icon from '../assets/loginsettings/backArrow_icon.svg';
import { Checkbox } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import axios from 'axios';
import { useLocation, useNavigate } from 'react-router-dom';
import { checkSuccessCode } from '../../utills/validator';
import { antdMessageError } from '../common/antdMessageComponent';
import { setAuthToken } from '../../Reducer/commonData';
import { CSCSendEvent, eventLocation, eventType } from '../../analytics';
import { handleAuthCodeGeneration } from '../../utills/common';
import CTALoader from '../../utills/ctaLoader';
import { isValidEmail, isValidNumber } from '../user/utills';

export function NewVarifyOTP() {
  const queryParams = new URLSearchParams(window.location.search);
  const redirectUrl = queryParams.get('redirectUrl');
  const commanUrlValue = useSelector((state) => state.commanUrlValue);
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const data = useSelector((state) => state.newClientConfig.otp);
  const clientDetails = useSelector((state) => state.clientDetails);
  const toggleData = useSelector((state) => state.loginSetting);
  const clientGroupId = useSelector((state) => state.clientGroupId);
  const [counter, setCounter] = useState(59);
  const [otp, setOtp] = useState(['', '', '', '']);
  const [showResendCode, setShowResendCode] = useState(true);
  const [enableSubmitButton, setEnableSubmitButton] = useState(false);
  const userEmail = queryParams.get('email');
  const userPhone = queryParams.get('phone');
  const [enableCheckbox, setEnableCheckbox] = useState({
    enableOffers: false,
    enableNotifications: false,
  });
  const [loading, setLoading] = useState(false);

  async function resendOTP() {
    try {
      await axios({
        method: 'post',
        url: `${process.env.REACT_APP_API_URL}/sso/resend-otp`,
        data: {
          clientGroupId: clientGroupId,
          clientId: commanUrlValue.clientId,
          ...((location?.state?.phone || userPhone) && { phone: isValidNumber(location?.state?.phone || userPhone) }),
          ...((location?.state?.email || userEmail) && { email: isValidEmail(location?.state?.email || userEmail) }),
        },
      });
    } catch (error) {
      antdMessageError(error?.response?.data?.message);
    }
    setShowResendCode(false);
    startTimerForResend(60);
  }

  const verify_otp = async (otp) => {
    setLoading(true);
    try {
      const verify_otp_response = await axios({
        method: 'post',
        url: `${process.env.REACT_APP_API_URL}/sso/verify-otp`,
        data: {
          code: Number(otp),
          clientGroupId: clientGroupId,
          clientId: commanUrlValue.clientId,
          enableOffers: enableCheckbox?.enableOffers,
          enableNotifications: enableCheckbox?.enableNotifications,
          ...((location?.state?.phone || userPhone) && { phone: isValidNumber(location?.state?.phone || userPhone) }),
          ...((location?.state?.email || userEmail) && { email: isValidEmail(location?.state?.email || userEmail) }),
        },
      });
      if (checkSuccessCode(verify_otp_response)) {
        localStorage.setItem('code', verify_otp_response?.data?.code);
        if (location?.state?.backRoute === '/forgetpassword') {
          dispatch(setAuthToken(verify_otp_response?.data?.code));
          navigate(`/resetpassword?${queryParams.toString()}`);
        } else {
          handleAuthCodeGeneration(
            verify_otp_response?.data?.code,
            redirectUrl,
            commanUrlValue,
            commanUrlValue.clientId,
            navigate,
            clientDetails,
          );
        }
        setOtp(['', '', '', '']);
      }
      setLoading(false);
    } catch (error) {
      setOtp(['', '', '', '']);
      setLoading(false);
      antdMessageError(error?.response?.data?.message);
    }
  };
  let counting = false;
  const startTimerForResend = (count = 60) => {
    if (!counting) {
      counting = true;
      count--;
      const timer = setInterval(function () {
        if (count > 0) {
          setCounter(count);
          count--;
        } else {
          setCounter(60);
          clearInterval(timer);
          counting = false;
          setShowResendCode(true);
        }
      }, 1000);
    }
  };

  const handleChange = (index, value) => {
    const newOtp = [...otp];
    newOtp[index] = value;
    setOtp(newOtp);
    if (index < otp.length - 1 && value !== '') {
      document.getElementById(`digit${index + 2}`).focus();
    }
    setEnableSubmitButton(isOtpComplete(newOtp));
  };
  const handleBackspace = (index, event, digit) => {
    if (event.key === 'Backspace') {
      const newOtp = [...otp];
      newOtp[index] = '';
      setOtp(newOtp);
      if (index === 3 && digit !== '') {
        document.getElementById(`digit${index + 1}`).focus();
      } else if (index > 0) {
        document.getElementById(`digit${index}`).focus();
      }
      setEnableSubmitButton(isOtpComplete(newOtp));
    } else if (event.key === 'Enter') {
      verify_otp(otp.join(''));
    }
  };

  const isOtpComplete = (otp) => {
    return otp.every((value) => value !== '');
  };

  const getButtonStyle = (data) => {
    return { ...data?.buttonText, opacity: enableSubmitButton ? '1' : '0.5' };
  };

  useEffect(() => {
    const ssoVerifyOtpViewObject = {
      eventType: eventType.VIEW,
      eventLocation: eventLocation.SSO_FORGET_PASSWORD_OTP,
      clientId: commanUrlValue?.clientId,
      anonId: commanUrlValue?.anonId,
      paywallId: commanUrlValue?.paywallId,
      url: decodeURIComponent(window.location.href || ''),
      segmentId: commanUrlValue?.segmentId,
      journeyId: commanUrlValue?.journeyId,
    };
    CSCSendEvent(ssoVerifyOtpViewObject);
    setShowResendCode(false);
    startTimerForResend(60);
  }, []);

  useEffect(() => {
    const handleBeforeUnload = () => {
      localStorage.setItem('redirected', 'true');
    };
    window.addEventListener('beforeunload', handleBeforeUnload);
    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, []);

  useEffect(() => {
    const redirected = localStorage.getItem('redirected');
    if (redirected) {
      localStorage.removeItem('redirected');
      navigate(`/login?${queryParams.toString()}`);
    }
  }, [navigate]);

  const handleBackClick = () => {
    const queryParams = new URLSearchParams(location.search);

    queryParams.delete('email');
    queryParams.delete('phone');

    navigate(`${location?.state?.backRoute}?${queryParams.toString()}`);
  };

  return (
    <div className={loginStyle['main-container']}>
      <div className={loginStyle['header-logo']}>
        <img className={loginStyle['image-logo']} src={toggleData?.brandLogoUrl || Demo_image} alt="Demo_image" />
      </div>
      <div className={loginStyle['p-4']}></div>
      <div className={loginStyle['container']}>
        <div className={loginStyle['child-container']}>
          <div className={loginStyle['backArrow_icon']}>
            <img src={BackArrow_icon} alt="BackArrow" onClick={handleBackClick} />
          </div>
          <div className={loginStyle['heading-container']}>
            <div className={loginStyle['verify_otp']} style={data?.formTitle}>
              {data?.formTitle?.text || 'Verify OTP'}
            </div>
          </div>
          <div className={loginStyle['setpasswprd-form-container']}>
            <div className={loginStyle['message']} style={data?.formSubtitle}>
              {data?.formSubtitle?.text || 'A code has been sent to '}
              {clientDetails.primary === 'phone' &&
                isValidNumber(location.state.phone || userPhone) &&
                `${(location?.state?.phone || userPhone).slice(0, 3)}******${(
                  location?.state?.phone || userPhone
                ).slice(-3, (location?.state?.phone || userPhone).length)}`}

              {clientDetails.primary === 'email' &&
                isValidEmail(location.state.email || userEmail) &&
                `${(location?.state?.email || userEmail).slice(0, 3)}******${(
                  location?.state?.email || userEmail
                ).slice(-3, (location?.state?.email || userEmail).length)}`}
            </div>
            <div className={loginStyle['otpForm']}>
              {otp.map((digit, index) => (
                <input
                  key={index}
                  type="tel"
                  id={`digit${index + 1}`}
                  value={digit}
                  maxLength="1"
                  className={loginStyle['inputField']}
                  onChange={(e) => {
                    const value = e.target.value;
                    if (value.length <= 1 && /^[0-9]?$/.test(value)) {
                      handleChange(index, value);
                    }
                  }}
                  onKeyDown={(e) => handleBackspace(index, e, digit)}
                />
              ))}
            </div>
            <div className={loginStyle['resend-otp-container']}>
              {showResendCode ? (
                <span
                  className={loginStyle['resend-otp']}
                  style={data?.timerText}
                  onClick={() => {
                    resendOTP();
                  }}
                >
                  {data?.timerText?.text || 'Resend OTP'}{' '}
                </span>
              ) : (
                <span className={loginStyle['resend-otp-time']}>
                  {data?.timerText?.text || 'Resend OTP in'} 00 : {counter} sec
                </span>
              )}
            </div>

            {toggleData?.optins?.[0]?.isRequired && (
              <div className={loginStyle['some-checks']}>
                <Checkbox
                  checked={enableCheckbox.enableOffers}
                  onChange={(e) => {
                    setEnableCheckbox({ ...enableCheckbox, enableOffers: e.target.checked });
                  }}
                />
                <div style={data?.OptIn1}>{data?.OptIn1?.text || 'Receive offers, receipts, news & event updates'}</div>
              </div>
            )}
            {toggleData?.optins?.[1]?.isRequired && (
              <div className={loginStyle['some-checks']}>
                <Checkbox
                  checked={enableCheckbox.enableNotifications}
                  onChange={(e) => {
                    setEnableCheckbox({ ...enableCheckbox, enableNotifications: e.target.checked });
                  }}
                />
                <div style={data?.OptIn2}>
                  {data?.OptIn2?.text || 'Get notified on Whatsapp, Get notified on Whatsapp'}
                </div>
              </div>
            )}
            <div className={loginStyle['by-continue']}>
              By continuing, you agree to our{' '}
              <a
                className={loginStyle['by-noHover']}
                href={toggleData?.redirects?.tnc}
                rel="noopener noreferrer"
                target="_blank"
              >
                Terms and Conditions
              </a>{' '}
              and{' '}
              <a
                className={loginStyle['by-noHover']}
                href={toggleData?.redirects?.privPolicy}
                rel="noopener noreferrer"
              >
                Privacy Policy
              </a>
            </div>
          </div>
          <button
            disabled={!enableSubmitButton}
            className={loginStyle['btn-style']}
            style={getButtonStyle(data)}
            onClick={() => verify_otp(otp.join(''))}
          >
            {loading ? <CTALoader /> : data?.buttonText?.text || 'Submit'}
          </button>
        </div>
      </div>
    </div>
  );
}
