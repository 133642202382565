import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import style from '../Style/Login.module.css';
import user from '../assets/user.svg';
import google from '../assets/google.svg';
import { useGoogleLogin } from '@react-oauth/google';
import apple from '../assets/apple.svg';
import facebook from '../assets/facebook.svg';
import { useNavigate } from 'react-router-dom';
import { eventType, eventLocation, CSCSendEvent, clickAction } from '../../analytics';
import axios from 'axios';
import {
  ValidateMobileNumber,
  googleLogInHandlerForMobile,
  validateEmail,
  errorHandlerForMobile,
} from '../../utills/validator';
import { antdMessageError } from '../common/antdMessageComponent';

const SignUp = () => {
  const uiconfigdata = useSelector((state) => state.clientConfig);
  const uiconfigparsed = useSelector((state) => state.parsedValue.signIn);
  const navigate = useNavigate();
  const [uiConfig, setuiConfig] = useState({});
  const [phoneNubmer, setPhoneNumber] = useState('');
  const [loginType, setLoginType] = useState('');
  const [enableSubmitButton, setEnableSubmitButton] = useState(false);
  const commanUrlValue = useSelector((state) => state.commanUrlValue);

  const queryParams = new URLSearchParams(window.location.search);
  const redirectUrl = queryParams.get('redirectUrl');
  const clientId = queryParams.get('clientId');
  const anonId = queryParams.get('anonId');
  const mobileView = queryParams.get('mobileView') || false;
  useEffect(() => {
    if (uiconfigparsed?.SignIn) {
      setuiConfig(uiconfigparsed?.SignIn);
    }
  }, [uiconfigparsed?.SignIn]);
  useEffect(() => {
    const keyDownHandler = (event) => {
      if (event.key === 'Enter') {
        if (enableSubmitButton === true) {
          sendOTP();
        }
      }
    };
    document.addEventListener('keydown', keyDownHandler);
    return () => {
      document.removeEventListener('keydown', keyDownHandler);
    };
  }, [enableSubmitButton]);
  async function sendOTP() {
    try {
      await axios.post(`${process.env.REACT_APP_API_URL}/sso/register`, {
        clientGroupId: uiconfigdata.groupId,
        phone: phoneNubmer,
        email: email,
        clientId,
        paywallId: commanUrlValue.paywallId,
        paywallType: commanUrlValue.paywallType,
      });
      const ssoSignupEventObject = {
        eventType: eventType.CLICK,
        eventLocation: eventLocation.SSO_SIGNUP,
        clickAction: loginType,
        clientId: clientId,
        anonId: anonId,
        url: decodeURIComponent(redirectUrl || ''),
        segmentId: commanUrlValue?.segmentId,
        journeyId: commanUrlValue?.journeyId,
      };
      CSCSendEvent(ssoSignupEventObject);
      navigate(
        `/VerifyOTP?redirectUrl=${redirectUrl}&clientId=${clientId}&pageType=${eventLocation.SSO_SIGNUP}&anonId=${anonId}`,
        {
          state: { phoneNubmer, email, loginType, origin: 'signUp' },
        },
      );
    } catch {
      const value = JSON.stringify({
        message: 'Looks like you already have an account! Please log in.',
        errorCode: '200',
      });
      errorHandlerForMobile(value);
      navigate(`/signin?redirectUrl=${redirectUrl}&clientId=${clientId}`, {
        state: { phoneNubmer, email },
      });
    }
  }
  const handleGoogleLogin = useGoogleLogin({
    onSuccess: async (tokenResponse) => {
      const googleLoginResponse = await axios({
        method: 'post',
        url: `${process.env.REACT_APP_API_URL}/sso/google-login`,
        data: {
          token: tokenResponse.access_token,
          clientGroupId: uiconfigdata.groupId,
        },
      });
      if (redirectUrl) {
        const fetchRedirectUrl = new URL(redirectUrl);
        fetchRedirectUrl.searchParams.set('authorizationCode', googleLoginResponse.data.authorizationCode);
        window.location.href = fetchRedirectUrl.href;
      } else {
        antdMessageError('A redirect URL is required but not provided. Please check and try again.');
      }
    },
    flow: 'implicit',
  });
  const callGoogleLogin = () => {
    if (mobileView) {
      googleLogInHandlerForMobile(JSON.stringify({ message: 'GOOGLE_LOGIN_CLICK' }));
    } else {
      handleGoogleLogin();
    }
    const ssoSignupEventObject = {
      eventType: eventType.CLICK,
      eventLocation: eventLocation.SSO_SIGNUP,
      clickAction: clickAction.GOOGLE,
      clientId: clientId,
      anonId: anonId,
      url: decodeURIComponent(redirectUrl || ''),
      segmentId: commanUrlValue?.segmentId,
      journeyId: commanUrlValue?.journeyId,
    };
    CSCSendEvent(ssoSignupEventObject);
  };

  const callFacebookLogin = () => {
    const ssoSignupEventObject = {
      eventType: eventType.CLICK,
      eventLocation: eventLocation.SSO_LOGIN,
      clickAction: clickAction.FACEBOOK,
      clientId: clientId,
      anonId: anonId,
      url: decodeURIComponent(redirectUrl || ''),
      segmentId: commanUrlValue?.segmentId,
      journeyId: commanUrlValue?.journeyId,
    };
    CSCSendEvent(ssoSignupEventObject);
  };
  const detectedCountry = 'IN';
  const [email, setemail] = useState('');
  const checkinput = (value, detectedCountry) => {
    if (uiconfigdata.enableEmailLogin) {
      if (validateEmail(value.toLowerCase())) {
        setemail(value.toLowerCase());
        setLoginType('EMAIL');
        return setEnableSubmitButton(true);
      }
    }
    if (uiconfigdata.enableMobileLogin) {
      if (ValidateMobileNumber(value)) {
        if (detectedCountry === 'IN') {
          setPhoneNumber(value);
          setLoginType('PHONE');
          return setEnableSubmitButton(true);
        } else {
          return setEnableSubmitButton(false);
        }
      }
    }
    setEnableSubmitButton(false);
  };
  useEffect(() => {
    const ssoLoginViewObject = {
      eventType: eventType.VIEW,
      eventLocation: eventLocation.SSO_SIGNUP,
      clientId: clientId,
      anonId: anonId,
      url: decodeURIComponent(redirectUrl || ''),
      segmentId: commanUrlValue?.segmentId,
      journeyId: commanUrlValue?.journeyId,
    };
    CSCSendEvent(ssoLoginViewObject);
  }, []);
  return (
    <div className={style['main-container']}>
      <div className={style['container']}>
        <div>
          <div className={style['brand']}>
            <img className={style['logo']} src={uiconfigdata?.brandLogoUrl} alt="Logo" />
            <p className={style['logMessage']} style={{ color: uiconfigparsed?.mainTitle?.color }}>
              {uiconfigparsed?.mainTitle?.text || 'Hi, let’s create your account'}
            </p>
            <p
              className={`${style['margin-subheading']} ${style['message']}`}
              style={{ color: uiConfig?.subTitle?.color }}
            >
              {uiconfigparsed?.subTitle?.text || 'Please enter your details below'}
            </p>
          </div>
        </div>
        <div className={style['login-form']}>
          <div className={style['inputFieldContainer']}>
            <img src={user} className={style['inputIcon']} alt="" />
            <input
              onChange={(e) => {
                checkinput(e.target.value, detectedCountry);
              }}
              placeholder="Mobile / Email"
              className={style['inputField']}
              type="text"
              maxLength={60}
            />
          </div>
          <button
            disabled={!enableSubmitButton}
            onClick={() => sendOTP()}
            style={{
              opacity: !enableSubmitButton ? '0.5' : '1',
              color: uiConfig?.formTitle?.color,
            }}
            className={style['sign-up-btn']}
          >
            Sign up
          </button>
          <p className={style['already-account']}>
            Already have an account?{' '}
            <span
              onClick={() => {
                navigate(`/signin?redirectUrl=${redirectUrl}&clientId=${clientId}`);
              }}
              className={style['redirect-sign']}
            >
              Sign in
            </span>
          </p>
        </div>
        <div>
          <div className={`${style['continueWithContainer']} ${style['sso-login-divider']}`}>
            <hr className={style['horizontalLine']} />
            <div className={style['continueWith']}>Or</div>
            <hr className={style['horizontalLine']} />
          </div>
          <div className={style['sso-login']}>
            {uiconfigdata.enableGoogleLogin ? (
              <div
                onClick={() => {
                  callGoogleLogin();
                }}
                className={style['google-login']}
              >
                <img src={google} alt="" />
                Google
              </div>
            ) : (
              <></>
            )}
            {uiconfigdata.enableFacebookLogin ? (
              <div
                className={style['facebook-login']}
                onClick={() => {
                  callFacebookLogin();
                }}
              >
                <img src={facebook} alt="" />
                Facebook
              </div>
            ) : (
              <></>
            )}
          </div>
          {uiconfigdata.enableAppleLogin ? (
            <div className={style['apple-login']}>
              <img src={apple} alt="" />
              Apple
            </div>
          ) : (
            <></>
          )}
        </div>
      </div>
    </div>
  );
};
export default SignUp;
