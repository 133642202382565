import React, { useEffect, useState } from 'react';
import style from '../Style/VerifyOTP.module.css';
import { useLocation } from 'react-router-dom';
import axios from 'axios';
import { eventType, clickAction, CSCSendEvent } from '../../analytics';
import { useNavigate } from 'react-router-dom';
import { loginHandlerForMobile, errorHandlerForMobile } from '../../utills/validator';
import { addUserInfo } from '../../Reducer/commonData';
import { useDispatch, useSelector } from 'react-redux';
import { antdMessageError } from '../common/antdMessageComponent';
// Inside your component

// Access data

const VerifyOTP = () => {
  const location = useLocation();
  const queryParams = new URLSearchParams(window.location.search);
  const redirectUrl = queryParams.get('redirectUrl');
  const clientId = queryParams.get('clientId');
  const anonId = queryParams.get('anonId');
  const pageType = `${queryParams.get('pageType')}_OTP`;

  const dispatch = useDispatch();
  const userEmail = location?.state?.email;
  const userPhoneNumber = location?.state?.phoneNubmer;
  const uiconfigdata = useSelector((state) => state.clientConfig);
  const commanUrlValue = useSelector((state) => state.commanUrlValue);
  const [counter, setCounter] = useState(59);
  const [otp, setOtp] = useState(['', '', '', '']);
  const [showResendCode, setShowResendCode] = useState(true);

  const navigate = useNavigate();
  async function resendOTP() {
    await axios.post(`${process.env.REACT_APP_API_URL}/sso/login`, {
      clientGroupId: uiconfigdata.groupId,
      phone: userPhoneNumber,
      email: userEmail,
      paywallId: commanUrlValue.paywallId,
      paywallType: commanUrlValue.paywallType,
    });
    setShowResendCode(false);
    startTimerForResend(60);
  }
  useEffect(() => {
    if (location.state === null) {
      navigate(`/SignUp?redirectUrl=${redirectUrl}&clientId=${clientId}`);
    }
  }, [location.state, navigate]);
  const submitotp = async (otp) => {
    try {
      const userData = await axios({
        method: 'post',
        url: `${process.env.REACT_APP_API_URL}/sso/verify-otp`,
        data: {
          code: Number(otp),
          phone: location.state.phoneNubmer,
          clientGroupId: uiconfigdata.groupId,
          email: location.state.email,
          clientId,
        },
      });
      localStorage.setItem('code', userData.data.code);

      dispatch(addUserInfo(userData.data.code));
      try {
        const codedata = await axios({
          method: 'POST',
          url: `${process.env.REACT_APP_API_URL}/sso/generate-auth-code`,
          headers: { Authorization: `Bearer ${userData.data.code}` },
        });
        if (redirectUrl) {
          if (redirectUrl === 'https://conscent-app-sdk') {
            //condition for mobile app

            try {
              const dataformobile = {
                token: codedata.data.authCode,
                status: true,
              };
              loginHandlerForMobile(JSON.stringify(dataformobile));
            } catch (err) {
              console.log(err, 'error while sending data to mobile app');
            }
          } else {
            const fetchRedirectUrl = new URL(redirectUrl);
            fetchRedirectUrl.searchParams.set('authorizationCode', codedata.data.authCode);
            if (commanUrlValue.origin === 'subsPage') {
              navigate(`/address?clientId=${clientId}&purchaseType=${commanUrlValue.purchaseType}`);
            } else {
              window.location.href = fetchRedirectUrl.href;
            }
          }
        } else {
          antdMessageError(' A redirect URL is required but not provided. Please check and try again.');
        }
      } catch (err) {
        console.log(err, 'error on generating auth code');
      }
    } catch (err) {
      if (err.response.data.message === 'incorrect otp') {
        const value = JSON.stringify({ message: 'The OTP is incorrect. Please try again.', errorCode: '402' });
        errorHandlerForMobile(value);
        antdMessageError('The OTP is incorrect. Please try again.');
      }
    }
  };
  let counting = false;
  const startTimerForResend = (count) => {
    if (!counting) {
      counting = true;
      count--;
      const timer = setInterval(function () {
        if (count > 0) {
          setCounter(count);
          count--;
        } else {
          setCounter(60);
          clearInterval(timer);
          counting = false;
          setShowResendCode(true);
        }
      }, 1000);
    }
  };

  const handleChange = (index, value) => {
    const newOtp = [...otp];
    newOtp[index] = value;
    setOtp(newOtp);
    if (index < otp.length - 1 && value !== '') {
      document.getElementById(`digit${index + 2}`).focus();
    }
  };
  const callOTPSubmit = () => {
    const ssoLoginClickObject = {
      eventType: eventType.CLICK,
      eventLocation: pageType,
      clickAction: pageType === 'SSO_LOGIN_OTP' ? clickAction.SSO_LOGIN_OTP_BTN : clickAction.SSO_SIGNUP_OTP_BTN,
      clientId: clientId,
      anonId: anonId,
      url: decodeURIComponent(redirectUrl || ''),
      segmentId: commanUrlValue?.segmentId,
      journeyId: commanUrlValue?.journeyId,
    };
    CSCSendEvent(ssoLoginClickObject);
    navigate(`/signin?redirectUrl=${redirectUrl}&clientId=${clientId}`);
  };
  useEffect(() => {
    if (otp.join('').length === 4) {
      submitotp(otp.join(''));
    }
  }, [otp]);
  const handleBackspace = (index, event, digit) => {
    if (event.key === 'Backspace') {
      const newOtp = [...otp];
      newOtp[index] = '';
      setOtp(newOtp);
      if (index === 3 && digit !== '') {
        document.getElementById(`digit${index + 1}`).focus();
      } else if (index > 0) {
        document.getElementById(`digit${index}`).focus();
      }
    }
  };
  useEffect(() => {
    const ssoOTPEventObj = {
      eventType: eventType.VIEW,
      eventLocation: pageType,
      clientId: clientId,
      anonId: anonId,
      url: decodeURIComponent(redirectUrl || ''),
      segmentId: commanUrlValue?.segmentId,
      journeyId: commanUrlValue?.journeyId,
    };
    CSCSendEvent(ssoOTPEventObj);
  }, []);
  return (
    <div className={style['main-container']}>
      <div className={style['container']}>
        <p className={style['Title']}>Verify OTP</p>
        <p className={style['message']}>
          {' A code has been sent to'}{' '}
          {userEmail
            ? userEmail.slice(0, 3) + '******' + userEmail.slice(-3, userEmail.length)
            : '' || userPhoneNumber
            ? userPhoneNumber.slice(0, 2) + '******' + userPhoneNumber.slice(-2, userPhoneNumber.length)
            : ''}
          {/* A code has been sent to {location?.state?.phoneNubmer||location?.state?.email} */}
        </p>
        <form>
          <div className={style['otpForm']}>
            {otp.map((digit, index) => (
              <input
                key={index}
                type="tel"
                id={`digit${index + 1}`}
                value={digit}
                maxLength="1"
                className={style['inputField']}
                onChange={(e) => {
                  const value = e.target.value;
                  if (value.length <= 1 && /^[0-9]?$/.test(value)) {
                    handleChange(index, e.target.value);
                  }
                }}
                onKeyDown={(e) => handleBackspace(index, e, digit)}
              />
            ))}
          </div>
          <div className={style['resend-otp-container']}>
            {showResendCode ? (
              <span
                onClick={() => {
                  resendOTP();
                }}
                className={style['resend-otp']}
              >
                Resend OTP
              </span>
            ) : (
              <span className={style['resend-otp-time']}> 00 : {counter} sec</span>
            )}
          </div>
          <button
            type="button"
            id="submitBtn"
            onClick={() => {
              callOTPSubmit();
            }}
            className={style['sign-up-btn']}
          >
            Go back home
          </button>
          {/* <p className={style["incorrect-password"]}>
            Incorrect OTP. 3 attempts left
          </p> */}
        </form>
      </div>
    </div>
  );
};
export default VerifyOTP;
