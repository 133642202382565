import React, { useState } from 'react';
import style from './ad-blocker.module.css';
import Back_icon from '../assets/ad-blocker/Group 100.svg';
import { adBlockerData, AdblockPlus } from './const';
import { Modal } from 'antd';

const AdBlocker = () => {
  const [active, setActive] = useState('Adblock Plus');
  const find = adBlockerData?.find((e) => e.name === active);
  return (
    <Modal
      className={style['container']}
      width={'75%'}
      centered={true}
      open={true}
      closable={false}
      footer={false}
      closeIcon={false}
    >
      <img
        src={Back_icon}
        alt={'Back_icon'}
        className={style['ad-blocker-back']}
        onClick={() => {
          const dataToSend = {
            message: 'closeModal',
          };
          window.parent.postMessage(dataToSend, '*');
        }}
      />
      <div className={style['ad-blocker-container']}>
        <div className={style['child-container1']}>
          <div className={style['h-1']}>Select your blocker:</div>
          {adBlockerData?.map((e, i) => (
            <div
              key={i}
              className={style['flex-center']}
              style={active === e.name ? { backgroundColor: '#ffffff' } : {}}
              onClick={() => setActive(e.name)}
            >
              <img src={e.icon} alt={e.name} className={style['ad-blocker-img']} />
              <div className={style['ad-blocker-name']}>{e.name}</div>
            </div>
          ))}
        </div>
        <div className={style['child-container2']}>
          <AdblockPlus title={find?.headingTitle} list={find?.instruction} img={find?.img} />
        </div>
      </div>
    </Modal>
  );
};

export default AdBlocker;
