import React from 'react';

const CrossIcon = ({fill}) => {
    return (

<svg xmlns="http://www.w3.org/2000/svg" width="34" height="34" viewBox="-5 -5 39 39" fill="none">
<path xmlns="http://www.w3.org/2000/svg" d="M16.6667 0C25.8717 0 33.3333 7.46167 33.3333 16.6667C33.3333 25.8717 25.8717 33.3333 16.6667 33.3333C7.46167 33.3333 0 25.8717 0 16.6667C0 7.46167 7.46167 0 16.6667 0ZM22.55 10.7833L22.41 10.6617C22.1976 10.5047 21.941 10.419 21.6769 10.4169C21.4128 10.4148 21.1548 10.4964 20.94 10.65L20.7833 10.7833L16.6667 14.8983L12.55 10.7817L12.41 10.6617C12.1976 10.5047 11.941 10.419 11.6769 10.4169C11.4128 10.4148 11.1548 10.4964 10.94 10.65L10.7833 10.7833L10.6617 10.9233C10.5047 11.1357 10.419 11.3923 10.4169 11.6564C10.4148 11.9205 10.4964 12.1785 10.65 12.3933L10.7833 12.55L14.8983 16.6667L10.7817 20.7833L10.6617 20.9233C10.5047 21.1357 10.419 21.3923 10.4169 21.6564C10.4148 21.9205 10.4964 22.1785 10.65 22.3933L10.7833 22.55L10.9233 22.6717C11.1357 22.8286 11.3923 22.9143 11.6564 22.9164C11.9205 22.9185 12.1785 22.8369 12.3933 22.6833L12.55 22.55L16.6667 18.435L20.7833 22.5517L20.9233 22.6717C21.1357 22.8286 21.3923 22.9143 21.6564 22.9164C21.9205 22.9185 22.1785 22.8369 22.3933 22.6833L22.55 22.55L22.6717 22.41C22.8286 22.1976 22.9143 21.941 22.9164 21.6769C22.9185 21.4128 22.8369 21.1548 22.6833 20.94L22.55 20.7833L18.435 16.6667L22.5517 12.55L22.6717 12.41C22.8286 12.1976 22.9143 11.941 22.9164 11.6769C22.9185 11.4128 22.8369 11.1548 22.6833 10.94L22.55 10.7833Z" fill={fill}/>
</svg>

);
};

export default CrossIcon; 




