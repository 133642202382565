import React, { useEffect, useRef, useState } from 'react';
import loginStyle from './review-screens.module.scss';
import Demo_image from '../assets/loginsettings/demo_image.svg';
import BackArrow_icon from '../assets/loginsettings/backArrow_icon.svg';
import Email_icon from '../assets/loginsettings/email_icon.svg';
import { Input } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import ReCAPTCHA from 'react-google-recaptcha';
import { useNavigate, useLocation } from 'react-router-dom';
import axios from 'axios';
import { checkSuccessCode, validateEmail } from '../../utills/validator';
import { antdMessageError, antdMessageWarning } from '../common/antdMessageComponent';
import { setLoginDetails, authCode } from '../../Reducer/commonData';
import { CSCSendEvent, eventLocation, eventType } from '../../analytics';
import { isValidEmail, isValidNumber } from '../user/utills';

export function ForgotPassword() {
  const queryParams = new URLSearchParams(window.location.search);
  const location = useLocation();
  const commanUrlValue = useSelector((state) => state.commanUrlValue);
  const recaptchaRef = useRef(null);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const data = useSelector((state) => state.newClientConfig.forgotPassword);
  const toggleData = useSelector((state) => state.loginSetting);
  const clientGroupId = useSelector((state) => state.clientGroupId);
  const loginDetails = useSelector((state) => state.loginDetails);
  const [btnDisabled, setBtnDisabled] = useState(true);
  const [recaptcha, setRecaptcha] = useState({
    recaptchaVersion: '',
    recaptchaToken: '',
  });

  const handleTokenv2 = (token) => {
    setRecaptcha({ ...recaptcha, recaptchaToken: token });
  };

  const checkinput = (value) => {
    if (toggleData?.loginMethods?.enableEmailLogin && validateEmail(value.toLowerCase())) {
      setBtnDisabled(false);
      return dispatch(setLoginDetails({ ...loginDetails, email: value.toLowerCase() }));
    }
    if (toggleData?.loginMethods?.enableEmailLogin) {
      dispatch(setLoginDetails({ ...loginDetails, email: value.toLowerCase() }));
      setBtnDisabled(true);
    }
  };

  const sendOtp = async () => {
    try {
      const responseOtp = await axios({
        method: 'post',
        url: `${process.env.REACT_APP_API_URL}/sso/send-reset-password`,
        data: {
          clientGroupId: clientGroupId,
          clientId: commanUrlValue.clientId,
          ...(loginDetails?.phone && {
            phone: loginDetails?.phone,
          }),
          ...(loginDetails?.email && {
            email: loginDetails?.email,
          }),
        },
      });
      if (checkSuccessCode(responseOtp)) {
        navigate(`/newvarifyotp?${queryParams.toString()}`, {
          state: { backRoute: '/forgetpassword', email: loginDetails?.email, phone: loginDetails?.phone },
        });
      }
    } catch (error) {
      antdMessageError(error?.response?.data?.message);
    }
  };

  const getUserLoginDetails = async () => {
    let token = '';
    if (toggleData?.captcha?.enabled && toggleData?.captcha?.onLogin && toggleData?.captcha?.captchaVersion === 3) {
      token = await recaptchaRef.current.executeAsync();
    }
    try {
      const getDetailsResponse = await axios({
        method: 'get',
        url: `${process.env.REACT_APP_API_URL}/sso/check-account-status`,
        params: {
          clientGroupId: clientGroupId,
          clientId: commanUrlValue.clientId,
          email: isValidEmail(loginDetails?.email),
          phone: isValidNumber(loginDetails?.phone),
          ...(token && { token: token }),
          ...(recaptcha?.recaptchaToken && {
            token: recaptcha?.recaptchaToken,
          }),
          type: 'onReset',
        },
      });
      if (checkSuccessCode(getDetailsResponse)) {
        if (getDetailsResponse?.data?.status) {
          if (getDetailsResponse?.data?.validated) {
            sendOtp();
          } else {
            antdMessageError('The reCAPTCHA token is invalid. Please try again.');
          }
        } else {
          antdMessageWarning("We couldn't find a registered account. Want to sign up?");
        }
      }
    } catch (error) {
      return antdMessageWarning("We couldn't find a registered account. Want to sign up?");
    }
  };

  useEffect(() => {
    if (toggleData?.captcha?.captchaVersion === 2) {
      setRecaptcha({ ...recaptcha, recaptchaVersion: 'normal' });
    } else if (toggleData?.captcha?.captchaVersion === 3) {
      setRecaptcha({ ...recaptcha, recaptchaVersion: 'invisible' });
    }
  }, [toggleData?.captcha]);

  useEffect(() => {
    const handleBeforeUnload = () => {
      localStorage.setItem('redirected', 'true');
    };
    window.addEventListener('beforeunload', handleBeforeUnload);
    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, []);

  useEffect(() => {
    const redirected = localStorage.getItem('redirected');
    if (redirected) {
      localStorage.removeItem('redirected');
      navigate(`/login?${queryParams.toString()}`);
    }
  }, [navigate]);

  useEffect(() => {
    const ssoForgetPasswordViewObject = {
      eventType: eventType.VIEW,
      eventLocation: eventLocation.SSO_FORGET_PASSWORD,
      clientId: commanUrlValue?.clientId,
      anonId: commanUrlValue?.anonId,
      paywallId: commanUrlValue?.paywallId,
      url: decodeURIComponent(window.location.href || ''),
      segmentId: commanUrlValue?.segmentId,
      journeyId: commanUrlValue?.journeyId,
    };
    CSCSendEvent(ssoForgetPasswordViewObject);
  }, []);

  const keyDownHandler = (event) => {
    if (event.key === 'Enter') {
      if (!btnDisabled) {
        getUserLoginDetails();
      }
    }
  };

  const handleBackClick = () => {
    const queryParams = new URLSearchParams(location.search);

    // Remove email and phone parameters if they exist
    queryParams.delete('email');
    queryParams.delete('phone');

    // Navigate to the login page with the updated query parameters
    navigate(`/login?${queryParams.toString()}`);
  };

  return (
    <div className={loginStyle['main-container']}>
      <div className={loginStyle['header-logo']}>
        <img className={loginStyle['image-logo']} src={toggleData?.brandLogoUrl || Demo_image} alt="Demo_image" />
      </div>
      <div className={loginStyle['container']}>
        <div className={loginStyle['child-container']}>
          <div className={loginStyle['cross_back_icon']}>
            <img src={BackArrow_icon} alt="BackArrow" onClick={handleBackClick} />
          </div>
          <div className={loginStyle['heading-container']}>
            <div>
              <div className={loginStyle['verify_otp']} style={data?.formTitle}>
                {data?.formTitle?.text || 'Forgot Password?'}
              </div>
            </div>
          </div>
          <div className={loginStyle['setpasswprd-form-container']}>
            <div className={loginStyle['message']} style={data?.subtitle}>
              {data?.subtitle?.text || 'Enter Your Registered Email id'}
            </div>
            <div className={loginStyle['input-container-common']}>
              <img src={Email_icon} alt="Email_icon" />
              <Input
                placeholder={data?.forgetPasswordInput?.text || 'Email'}
                style={data?.forgetPasswordInput}
                onChange={(e) => checkinput(e.target.value)}
                onKeyDown={keyDownHandler}
              />
            </div>
            {toggleData?.captcha?.enabled && toggleData?.captcha?.onReset && recaptcha?.recaptchaVersion && (
              <div style={{ display: 'flex', justifyContent: 'center' }}>
                <ReCAPTCHA
                  ref={recaptchaRef}
                  size={recaptcha?.recaptchaVersion}
                  sitekey={toggleData?.captcha?.captchaKey}
                  onChange={handleTokenv2}
                />
              </div>
            )}
          </div>
          <button
            className={loginStyle['btn-style']}
            disabled={btnDisabled}
            style={{
              ...data?.buttonText,
              opacity: btnDisabled ? '0.5' : '1',
              cursor: btnDisabled ? 'not-allowed' : 'pointer',
            }}
            onClick={() => getUserLoginDetails()}
          >
            {data?.buttonText?.text || 'Get OTP'}
          </button>
        </div>
      </div>
    </div>
  );
}
