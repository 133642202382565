import style from './ad-blocker.module.css';
import Adblock_Plus_icon from '../assets/ad-blocker/adblock-plus.svg';
import Adblock_icon from '../assets/ad-blocker/adBlock.svg';
import UBlock_Origin_icon from '../assets/ad-blocker/uBlock-Origin.svg';
import UBlock_icon from '../assets/ad-blocker/uBlock.svg';
import AdGuard_icon from '../assets/ad-blocker/adGuard.svg';
import Brave_icon from '../assets/ad-blocker/brave.svg';
import Ad_remover_icon from '../assets/ad-blocker/adremover.svg';
import Ultrablock_icon from '../assets/ad-blocker/ultra_block.svg';
import Ghostery_icon from '../assets/ad-blocker/ghostery.svg';
import Firefox_icon from '../assets/ad-blocker/fire-Fox.svg';
import DuckDuckGo_icon from '../assets/ad-blocker/duk-duk-go.svg';
import Privacy_Badger_icon from '../assets/ad-blocker/privacy-badger.svg';
import Disconnect_icon from '../assets/ad-blocker/disconnect.svg';
import Opera_icon from '../assets/ad-blocker/opera.svg';
import Microsoft_Edge_icon from '../assets/ad-blocker/microsoft-edge.svg';
import VeePN_icon from '../assets/ad-blocker/vee-pn.svg';
import dummy_img from '../assets/ad-blocker/dummy_img.svg';

export const adBlockerData = [
  {
    icon: Adblock_Plus_icon,
    name: 'Adblock Plus',
    instruction: [
      'Click the Adblock Plus icon in the extension bar.',
      'Beneath “Block ads on”, click the large blue toggle next to “This website”.',
      'Refresh the page.',
    ],
    headingTitle: 'Adblock Plus Instructions',
    img: dummy_img,
  },
  {
    icon: Adblock_icon,
    name: 'Adblock',
    instruction: [
      'To the right of the address bar, click the Adblock icon (red stop sign with hand symbol).',
      'Click “Pause on this site”.',
    ],
    headingTitle: 'Adblock Instructions',
  },
  {
    icon: UBlock_Origin_icon,
    name: 'uBlock Origin',
    instruction: [
      'To the right of the address bar, click the uBlock Origin icon (dark red shield with the letters UO).',
      'Click on the big, blue power button.',
      'Refresh the webpage.',
    ],
    headingTitle: 'uBlock Origin Instructions',
    img: dummy_img,
  },
  {
    icon: UBlock_icon,
    name: 'uBlock',
    instruction: [
      'To the right of the address bar, click the uBlock icon (dark red shield with the letter U).',
      'Click the button “Allow ads on this site”.',
    ],
    headingTitle: 'uBlock Instructions',
    img: dummy_img,
  },
  {
    icon: AdGuard_icon,
    name: 'Adguard',
    instruction: ['Open AdGuard.', 'Click the large green toggle to turn it off.'],
    headingTitle: 'Easy Adguard Instructions',
    img: dummy_img,
  },
  {
    icon: AdGuard_icon,
    name: 'Adguard Extension',
    instruction: [
      'Click on the green AdGuard icon in the extension bar.',
      'Click the large green toggle to turn it off.',
    ],
    headingTitle: 'Adguard Extension Instructions',
  },
  {
    icon: Brave_icon,
    name: 'Brave',
    instruction: ['Click on the orange lion icon in the extension bar.', 'Toggle Brave Shields form UP to DOWN.'],
    headingTitle: 'Brave Instructions',
  },
  {
    icon: Ad_remover_icon,
    name: 'Adremover',
    instruction: [
      'To the right of the address bar, click the Ad Remover icon (dark blue shield).',
      'Click the red button Disable on This Website.',
    ],
    headingTitle: 'Adremover Instructions',
  },
  {
    icon: Ultrablock_icon,
    name: 'Ultrablock',
    instruction: [
      'To the right of the address bar, click the Ultrablock icon (green shield).',
      'Toggle “Block unnecessary services” off.',
    ],
    headingTitle: 'Ultrablock Instructions',
  },
  {
    icon: Ghostery_icon,
    name: 'Ghostery',
    instruction: [
      'To the right of the address bar, click the Ghostery icon (blue ghost).',
      'Toggle Ad-Blocking, Anti-Tracking, and Never-Consent from ON to OFF.',
    ],
    headingTitle: 'Ghostery Instructions',
  },
  {
    icon: Firefox_icon,
    name: 'Firefox Tracking Protection',
    instruction: [
      'Click on the shield icon on the left side of the address bar.',
      'Click the toggle to turn Enhanced Tracking Protection oFF.',
    ],
    headingTitle: 'Firefox Tracking Protection Instructions',
  },
  {
    icon: DuckDuckGo_icon,
    name: 'DuckDuckGo',
    instruction: [
      'Click on the shield icon on the left side of the address bar.',
      'Toggle “Protections are ON for this site” to OFF.',
    ],
    headingTitle: 'DuckDuckGo Instructions',
  },
  {
    icon: Privacy_Badger_icon,
    name: 'Privacy Badger',
    instruction: [
      'To the right of the address bar, click the Privacy Badger icon.',
      'Click the button “Disable for this site”.',
    ],
    headingTitle: 'Privacy Badger Instructions',
  },
  {
    icon: Disconnect_icon,
    name: 'Disconnect',
    instruction: ['To the right of the address bar, click the Disconnect “D” icon.', 'Click “Unblock site“'],
    headingTitle: 'Disconnect Instructions',
  },
  {
    icon: Opera_icon,
    name: 'Opera',
    instruction: [
      'To the right of the address bar, click the shield icon.',
      'Turn off “Ad Blocker” and “Tracker Blocker”.',
    ],
    headingTitle: 'Opera Instructions',
  },
  {
    icon: Microsoft_Edge_icon,
    name: 'Microsoft Edge',
    instruction: [
      'Click on the shield icon on the left side of the address bar.',
      'Change Tracking Prevention from “Block (Default)” to “Off”.',
    ],
    headingTitle: 'Microsoft Edge Instructions',
  },
  {
    icon: VeePN_icon,
    name: 'VeePN',
    instruction: ['Click the grey shield icon next to the URL bar.', 'Toggle AdBlock from green (on) to grey (off)'],
    headingTitle: 'VeePN Instructions',
  },
];

export const AdblockPlus = ({ title, list, img }) => {
  return (
    <div className={style['c-container']}>
      <div className={style['c-1']} style={{ width: img ? '45%' : '100%' }}>
        <div className={style['h-2']}>{title}</div>
        <ol type="1" className={style['ol']}>
          {list?.map((e, i) => (
            <li key={i}>{e}</li>
          ))}
        </ol>
      </div>
      {img && (
        <div className={style['c-2']}>
          <img src={img} alt="img" />
        </div>
      )}
    </div>
  );
};
